// @ts-nocheck

import React, { FC, Suspense, lazy } from "react";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Setup from "./pages/Setup";
import Login from "./pages/Login";
import DeviceLinks from "./pages/DeviceLinks";
import ReceiptViewer from "./pages/ReceiptViewer";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/layout/header";
import TransactionGenerator from "./pages/TransactionGenerator";
import { GlobalStateProvider } from "./lib/GlobalState";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ApiDocsRoutes } from "./pages/ApiDocs";

const Preload = (importStatement) => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const PayzoneSimulator = Preload(() => import("./pages/PayzoneSimulator"));

PayzoneSimulator.preload();

const CounterSimulator = Preload(() => import("./pages/CounterSimulator"));

CounterSimulator.preload();

const SandboxSimulator = Preload(() => import("./pages/SandboxSimulator"));
SandboxSimulator.preload();

const App: FC = () => (
  <Router>
    <ToastContainer
      autoClose={3000}
      hideProgressBar={false}
      draggable={false}
      limit={2}
      role="alert"
      transition={Flip}
    />
    <GlobalStateProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate replace to="/setup" />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/devices" element={<DeviceLinks />} />
        <Route path="/transaction-generator" element={<TransactionGenerator />} />
        <Route path="/receipt-viewer" element={<ReceiptViewer />} />
        <Route path="/api-docs/*" element={<ApiDocsRoutes />} />
        <Route
          path="/simulators/payzone"
          element={
            <Suspense fallback={<div>loading...</div>}>
              <PayzoneSimulator />
            </Suspense>
          }
        />
        <Route
          path="/simulators/journeysandbox"
          element={
            <Suspense fallback={<div>loading...</div>}>
              <SandboxSimulator />
            </Suspense>
          }
        />
        <Route
          path="/simulators/counterterminal"
          element={
            <Suspense fallback={<div>loading...</div>}>
              <CounterSimulator />
            </Suspense>
          }
        />
        <Route component={PageNotFound} />
      </Routes>
    </GlobalStateProvider>
  </Router>
);

export default App;
