import React, { FC, useEffect } from "react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { useNavigate, useLocation } from "react-router-dom";
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from "@aws-amplify/ui-react";
import { Constants } from "../constants";
import { useGlobalState } from "../lib/GlobalState";

const DEFAULT_REDIRECT: string = "/";

const Index: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sandboxParams } = useGlobalState();

  let returnUrl = location.search
    .substring(1)
    .split("&")
    .find((s) => s.startsWith("return="));

  returnUrl = returnUrl ? returnUrl.split("=")[1] : DEFAULT_REDIRECT;
  if (sandboxParams) {
    returnUrl = returnUrl + sandboxParams;
  }
  useEffect(() => {
    onAuthUIStateChange((newAuthState: AuthState) => {
      if (newAuthState === AuthState.SignedIn) {
        navigate(returnUrl as string, { replace: true });
      }
    });
  }, [navigate, returnUrl]);

  const signupFields = [
    {
      type: "email",
      required: true,
    },
    {
      type: "password",
      required: true,
    },
  ];

  return (
    <>
      <div className="bg-yellow-200 p-2 text-center text-sm">
        <h1>
          <strong>{Constants.PLEASE_NOTE}</strong>
          {Constants.VERIFICATION_CODE_MSG}
        </h1>
      </div>
      <AmplifyAuthenticator>
        <AmplifySignUp slot="sign-up" usernameAlias="email" formFields={signupFields} />
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
      </AmplifyAuthenticator>
    </>
  );
};

export default Index;
