import { Auth } from "aws-amplify";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import useUserStatus from "../../lib/hooks/useUserStatus";
import { useGlobalState } from "../../lib/GlobalState";
import { Tab } from "./tab";

const Header: FC = () => {
  const navigate = useNavigate();
  const { setSandboxParams } = useGlobalState();

  const handleLogout = async () => {
    await Auth.signOut();
    setSandboxParams(null);
    navigate("/login");
  };

  const handleLogin = async () => {
    setSandboxParams(null);
    navigate("/login");
  };

  const userStatus = useUserStatus();
  const isLoggedIn = null !== userStatus;

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <img className="block w-auto h-8" src={Logo} alt="Post Office" />
            </div>
            <Tab route="/setup" label="Device Simulator" />
            <Tab route="/devices" label="Your Devices" />
            <Tab route="/receipt-viewer" label="Receipt Viewer" />
            <Tab route="/transaction-generator" label="Transaction Generator" />
            <Tab route="/api-docs" label="API Docs" />
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <div className="relative ml-3">
              {isLoggedIn && (
                <button
                  data-test="auth-flow-btn"
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Log Out
                </button>
              )}
              {!isLoggedIn && (
                <button
                  data-test="auth-flow-btn"
                  onClick={handleLogin}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Log In
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
