import { Route, Routes } from "react-router-dom";
import { ApiDocsProject } from "./ApiDocsProject";
import { ApiDocsProjects } from "./ApiDocsProjects";
import { ApiDocsUnitTest } from "./ApiDocsUnitTest";
import { ApiDocsUnitTests } from "./ApiDocsUnitTests";

export const ApiDocsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ApiDocsProjects />} />
      <Route path="/project/:project" element={<ApiDocsProject />} />
      <Route path="/project/:project/unitTests" element={<ApiDocsUnitTests />} />
      <Route path="/project/:project/unitTests/:unitTest" element={<ApiDocsUnitTest />} />
    </Routes>
  );
};
